import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";


import "./time-line.scss";

import { timeInfoArray } from "../../data/timeline-data";


function TimeLine() {
	

	return (
		<div>
			<VerticalTimeline>
				{timeInfoArray.map(({ date, title, subTitle, logo, descriptions,icon, background, technologies }, i) => {
					return (
						<TimeCard
							key={i}
							date={date}
							title={title}
							subTitle={subTitle}
							logo={logo}
							descriptions={descriptions}
							icon={icon}
							background= {background}
							technologies={technologies}
						/>
					);
				})}
			</VerticalTimeline>
		</div>
	);
}

const TimeCard = ({ title, date, subTitle, descriptions, logo, icon, background, technologies }) => {
	console.log("techs: ", technologies)
	return (
		<VerticalTimelineElement
			className='vertical-timeline-element--work'
			date={date}
			iconStyle={{ background: `${background}`, color: "#fff" }}
			icon={icon}
		>
			<div className='time-card-header'>
				<div className='time-card-header-logo'>
					<img src={logo} alt={ title}/>
				</div>
				<div>
					<h3 className='vertical-timeline-element-title'>{title}</h3>
					<h4 className='vertical-timeline-element-subtitle'>{subTitle}</h4>
				</div>
			</div>

			<div className='time-card-description'>
				<ul>
					{descriptions
						? descriptions.map((item, i) => <li key={i}>{item.info}</li>)
						: null}
				</ul>
			</div>
			{/* {technologies && (
				<div className="technologies-used">
					<h5>Technologies Used:</h5>
					<div className="technologies-icons">
						{technologies.map((tech, i) => (
							<div key={i} className="technology-icon">
								{tech}
							</div>
						))}
					</div>
				</div>
			)} */}
		</VerticalTimelineElement>
	);
};

export default TimeLine;
