import { FiBriefcase } from "react-icons/fi";
import { FaGraduationCap } from "react-icons/fa";
import { IoMdBriefcase } from "react-icons/io";
import { FaReact, FaNodeJs, FaPython, FaAngular } from "react-icons/fa";
import { SiJavascript, SiDjango, SiMysql, SiMongodb } from "react-icons/si";

import logo1 from "../assets/timeline/action-trak.jpeg";
import logo2 from "../assets/timeline/elite-roofing.jpeg";
import logo3 from "../assets/timeline/consensus.jpeg";
import ensignLogo from "../assets/timeline/ensign.jpeg";
import uvuLogo from "../assets/timeline/uvu.jpeg";
import logo4 from "../assets/timeline/WC3-Logo.png";

export const timeInfoArray = [
    {
        date: "Apr 2023 - Present",
        title: "Back End Developer",
        subTitle: "WC-3",
        logo: logo4,
        icon: <IoMdBriefcase />,
        descriptions: [
            {
                info: "Designing and implementing from scratch the front-end and back-end features using micro-services and micro front-end architectures, ensuring modularity and scalability.",
            },
            {
                info: "Developing and maintaining dynamic web applications using React, Node.js, and MongoDB, with a focus on performance optimization and user-centric design.",
            },
            {
                info: "Managing deployments on Google Cloud Platform (GCP) using DevOps practices and configured CI/CD pipelines for seamless delivery.",
            },
        ],
        technologies: [<FaNodeJs />, <SiMongodb />,<SiJavascript />],
        background: "rgb(33, 150, 243)",
    },
    {
        date: "Jan 2022 - Apr 2023",
        title: "Full-Stack Developer",
        subTitle: "ActionTrak",
        logo: logo1,
        icon: <IoMdBriefcase />,
        descriptions: [
            {
                info: "Designing the Back-End API, so different interfaces like the Mobile and the Desktop Apps can use the server.",
            },
            {
                info: "Designing the Server App Architecture and Database Schemas.",
            },
        ],
        technologies: [<FaReact />, <FaNodeJs />, <SiMysql />, <SiJavascript />],
        background: "rgb(33, 150, 243)",
    },
    {
        date: "May 2018 - May 2022",
        title: "B.S. Software Engineering",
        subTitle: "Utah Valley University",
        logo: uvuLogo,
        icon: <FaGraduationCap />,
        background: "rgb(245, 0, 87)",
    },
    {
        date: "Nov 2017 - Feb 2019",
        title: "Full-Stack Developer",
        subTitle: "Elite Roofing",
        logo: logo2,
        icon: <IoMdBriefcase />,
        descriptions: [
            {
                info: "Designed and developed the web application UI and front-end using Angular.",
            },
            {
                info: "Created and developed back-end server app using NodeJS and ExpressJS.",
            },
            {
                info: "Created an application that helps managing members and facilitating communication among them.",
            },
        ],
        technologies: [<FaAngular />, <FaNodeJs />, <SiMysql />, <SiJavascript />],
        background: "rgb(33, 150, 243)",
    },
    {
        date: "Jun 2016 - Sep 2016",
        title: "QA Programmer (internship)",
        subTitle: "Consensus",
        logo: logo3,
        icon: <IoMdBriefcase />,
        descriptions: [
            {
                info: "Designed more than 15 integrated tests for QA team to use to test the company’s web app.",
            },
            {
                info: "Helped create a library for the testing environment that the company was used.",
            },
        ],
        technologies: [<FaPython />, <SiDjango />, <SiJavascript />],
        background: "rgb(33, 150, 243)",
    },
    {
        date: "Jul 2015 - Nov 2017",
        title: "Programming Tutor",
        subTitle: "Ensign College",
        logo: ensignLogo,
        icon: <IoMdBriefcase />,
        descriptions: [
            {
                info: "Helped student understand various programming concepts in Java, C#, Python, JavaScript.",
            },
            {
                info: "Assisted students with their homework and various questions they have.",
            },
        ],
        background: "rgb(33, 150, 243)",
    },
    {
        date: "Aug. 2017",
        title: "A.S Software Development",
        subTitle: "Ensign College",
        logo: ensignLogo,
        icon: <FaGraduationCap />,
        background: "rgb(245, 0, 87)",
    },
];
