export const skillsData = {
	title: "Skills",
	list: [
		{
			name: "JavaScript",
			level: 90,
		},
		{
			name: "Node",
			level: 80,
		},
		{
			name: "HTML",
			level: 95,
		},
		{
			name: "CSS/SCSS",
			level: 80,
		},
		{
			name: "React",
			level: 85,
		},
		{
			name: "SQL",
			level: 70
		},
		{
			name: "MongoDB",
			level: 80
		},
		{
			name: "Java",
			level: 90,
		},
		{
			name: "C#",
			level: 60,
		},
		// {
		// 	name: "C++",
		// 	level: 50,
		// },
		{
			name: "Angular",
			level: 55,
		},
		// {
		// 	name: "React Native",
		// 	level: 65,
		// },
	],
};