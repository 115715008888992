import React from "react";

import SkillsList from "../skills-list/SkillsList";

import { skillsData } from "../../data/skills-data";

import "./about.scss";

const About = () => {

	return (
		<div className='about-wrapper'>
			<div className="about-info">
				<h2>About Me</h2>
				<div className='about-page-text'>
					<p>
						Hi, I’m <b>Axan Similien</b>, a passionate and driven full-stack software engineer with a knack for building innovative solutions that solve real-world problems. I specialize in creating seamless, user-centric applications that blend cutting-edge technology with intuitive design.

						With experience spanning <b>back-end development, front-end technologies, and cloud computing</b>, I thrive at every stage of the software development lifecycle. Whether it’s designing robust APIs, implementing scalable architectures, or crafting pixel-perfect user interfaces, I bring a detail-oriented and creative approach to every project.

						I’m not just a coder—I’m a problem solver. My goal is to transform ideas into reliable, impactful software that meets both user and business needs. When I’m not coding, you’ll find me exploring new technologies, collaborating on open-source projects, or learning something new to stay ahead in this fast-paced industry.

						Let’s build something amazing together!
					</p>
				</div>
			</div>
			<div className='what-i-do'>
				<h2>What I can Do</h2>
				<div className='what-i-do-list'>
					<WhatIDoCard
						title='Web Development'
						iconC='bx bx-globe'
						description='I Enjoyed doing web App and Website using various technologies.'
					/>
					<WhatIDoCard
						title='Back-End'
						iconC='bx bx-server'
						description='I can use numerous technology to build Back End and API. For example: NodeJS, Java, C#.'
					/>
					<WhatIDoCard
						title='Front-End'
						iconC='bx bx-laptop'
						description='I use different libraries and framework to design UI. React, Angular, SCSS etc.'
					/>
				</div>
				<div className='what-i-do-list'>
					<WhatIDoCard
						title='Cloud and DevOps'
						iconC='bx bxl-google-cloud'
						description='Deploy applications on Google Cloud Platform (GCP) with a focus on scalability and reliability, leveraging Docker for containerization and Kubernetes for efficient orchestration and service management. 
						Additionally, design and implement CI/CD pipelines to automate and optimize development, testing, and deployment workflows.'
					/>
				</div>
			</div>

			<div className="skills-about">
				<h2>Skills</h2>
				<SkillsList skills={skillsData} />
			</div>
		</div>
	);
};

const WhatIDoCard = ({ title, iconC, description }) => {

	return (
		<div className='what-i-do-card'>
			<i className={iconC}></i>
			<h3>{title}</h3>
			<p>{description}</p>
		</div>
	);

}

export default About;
